import { containerless, inject } from 'aurelia-framework';
import { Router }                from 'aurelia-router';

@containerless
@inject(Router)
export class PageBreadcrumbs {

    /**
     * Constructor
     *
     * @param router
     */
    constructor(router) {
        this.router = router;
    }
}
