import { inject }                  from 'aurelia-framework';
import { Router }                  from 'aurelia-router';
import { UsersRepository }         from 'modules/administration/users/services/repository';
import { NotificationsRepository } from 'modules/personal-area/notifications/services/repository';
import { AuthenticatedUser }       from 'resources/services/authenticated-user';
import { LaravelEchoService }      from 'resources/services/laravel-echo-service';

@inject(Router, LaravelEchoService, AuthenticatedUser, UsersRepository, NotificationsRepository)
export class NotificationsMediaList {

    dropdownMenu;
    allNotificationsAnchor;

    eventListeners = [];
    notifications  = [];

    notificationsCount = 0;

    /**
     * Constructor
     *
     * @param router
     * @param laravelEchoService
     * @param authenticatedUser
     * @param usersRepository
     * @param notificationsRepository
     */
    constructor(router, laravelEchoService, authenticatedUser, usersRepository, notificationsRepository) {
        this.router                  = router;
        this.laravelEchoService      = laravelEchoService;
        this.authenticatedUser       = authenticatedUser;
        this.usersRepository         = usersRepository;
        this.notificationsRepository = notificationsRepository;
    }

    /**
     * Handles bind event
     *
     * @returns {Promise<any[]>}
     */
    bind() {
        return this.fetchData();
    }

    /**
     * Handles attached event
     */
    attached() {
        this.subscribeNotificationsChannel();
        this.subscribeEventListeners();
    }

    /**
     * Fetches data from remote source
     *
     * @returns {*}
     */
    fetchData() {
        return this.usersRepository.unreadNotifications().then((response) => {
            this.notifications.splice(0, this.notifications.length, ...response.notifications);

            this.notificationsCount = response.notificationsCount;
        });
    }

    /**
     * Subscribes notifications channel
     */
    subscribeNotificationsChannel() {
        if (this.laravelEchoService.instantiated) {
            window.Echo.private('App.User.' + this.authenticatedUser.user.id)
                .listen('UserNotificationsUpdated', (event) => {
                    const previousCount = this.notificationsCount;

                    this.notifications.splice(0, this.notifications.length, ...event.notifications);

                    this.notificationsCount = event.notificationsCount;

                    if (this.notificationsCount > previousCount) {
                        let audio = new Audio('/assets/audio/notification.mp3');

                        return audio.play();
                    }
                });
        }
    }

    /**
     * Navigates to a given notification
     *
     * @param notification
     */
    navigateToNotification(notification) {
        // marks the notification has read
        return this.notificationsRepository.read(notification.id)
            .then(() => {
                let route = (notification.route && notification.relatable)
                    ? this.router.generate(notification.route, {id: notification.relatable.id})
                    : this.router.generate('personal-area.notifications.details', {id: notification.id});

                // closes notifications dropdown
                $(this.dropdownMenu).removeClass('show');

                return this.router.navigate(route);
            });
    }

    /**
     * Subscribes event listeners
     */
    subscribeEventListeners() {
        // subscribes `all-notifications-anchor click event` in order to close notifications dropdown
        $(this.allNotificationsAnchor).on('click', () => $(this.dropdownMenu).removeClass('show'));
    }

    /**
     * Disposes event listeners
     */
    disposeEventListeners() {
        while (this.eventListeners.length) {
            this.eventListeners.pop().dispose();
        }
    }

}
