import { bindable, containerless } from 'aurelia-framework';
import { CountUp }                 from 'countup.js';

@containerless
export class CounterWidget {

    @bindable element;
    @bindable size;
    @bindable legend;
    @bindable icon;

    @bindable startValue  = 0;
    @bindable targetValue = 0;

    @bindable options = {};

    /**
     * Handles bind event
     */
    bind() {
        this.counter = new CountUp(this.element, this.targetValue, this.options);

        this.counter.start();
    }

    /**
     * Handles target value changed
     *
     * @param newTarget
     */
    targetValueChanged(newTarget) {
        this.counter.update(newTarget);
    }
}
