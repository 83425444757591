import { inject }       from 'aurelia-framework';
import { AppContainer } from 'resources/services/app-container';

@inject(AppContainer)
export class DiplomasQuickSearch {

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        this.appContainer = appContainer;
    }

    /**
     * Performs search
     */
    search() {
        let route = null;

        if (this.appContainer.authenticatedUser.hasCurrentTenant()) {
            route = 'legislation.client.personal-area.diplomas.index';
        } else if (this.appContainer.authenticatedUser.is('consultant')) {
            route = 'legislation.consultant.diplomas.index';
        } else if (this.appContainer.authenticatedUser.is('jurist')) {
            route = 'legislation.jurist.diplomas.index';
        }

        this.appContainer.router.navigateToRoute(route, {
            Number: this.diplomaNumber || null,
            Year:   this.diplomaYear || null,
        });

        this.resetForm();
    }

    /**
     * Resets form
     */
    resetForm() {
        this.diplomaNumber = null;
        this.diplomaYear   = null;
    }

}
