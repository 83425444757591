import environment from 'environment';

export class PageFooter {

    /**
     * Constructor
     */
    constructor() {
        this.environment = environment;
    }

}
