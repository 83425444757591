import { Aurelia, inject, PLATFORM } from 'aurelia-framework';
import { AuthService }               from 'aurelia-authentication';
import { AuthenticatedUser }         from 'resources/services/authenticated-user';

@inject(Aurelia, AuthService, AuthenticatedUser)
export class PageSidebarUserMenu {

    /**
     * Constructor
     *
     * @param aurelia
     * @param authService
     * @param authenticatedUser
     */
    constructor(aurelia, authService, authenticatedUser) {
        this.aurelia           = aurelia;
        this.authService       = authService;
        this.authenticatedUser = authenticatedUser;
    }

    /**
     * Logs out the logged user
     */
    logout() {
        this.authService.logout()
            .then(() => this.aurelia.setRoot(PLATFORM.moduleName('app-offline')))
            .catch(() => console.log('Error logging out'));
    }

}
