import { inject }            from 'aurelia-framework';
import { AuthenticatedUser } from 'resources/services/authenticated-user';
import { LocalStorage }      from 'resources/services/local-storage';

@inject(AuthenticatedUser, LocalStorage)
export class PageSidebar {

    /**
     * Constructor
     *
     * @param authenticatedUser
     * @param localStorage
     */
    constructor(authenticatedUser, localStorage) {
        this.authenticatedUser = authenticatedUser;
        this.localStorage      = localStorage;
    }

    /**
     * Handles attached event
     */
    attached() {
        // collapses sidebar based on stored setting
        if (isTrue(this.localStorage.get('sidebar-collapsed'))) {
            document.body.classList.add('sidebar-xs');
        }

        this.initSidebar();
        this.initsNavigations();
        this.initComponents();
    }

    /**
     * Inits components
     */
    initsNavigations() {
        this.navigationSidebar();
        this.navigationNavbar();
    }

    /**
     * Inits sidebar
     */
    initSidebar() {
        // On desktop
        this.sidebarMainResize();
        this.sidebarMainToggle();
        this.sidebarSecondaryToggle();
        this.sidebarRightMainToggle();
        this.sidebarRightMainHide();
        this.sidebarRightToggle();
        this.sidebarRightSecondaryToggle();
        this.sidebarComponentToggle();

        // On mobile
        this.sidebarMobileFullscreen();
        this.sidebarMobileMainToggle();
        this.sidebarMobileSecondaryToggle();
        this.sidebarMobileRightToggle();
        this.sidebarMobileComponentToggle();
    }

    /**
     * Inits components
     */
    initComponents() {
        this.dropdownSubmenu();
        this.headerElements();
    }

    navigationSidebar() {

        // Define default class names and options
        var navClass         = 'nav-sidebar',
            navItemClass     = 'nav-item',
            navItemOpenClass = 'nav-item-open',
            navLinkClass     = 'nav-link',
            navSubmenuClass  = 'nav-group-sub',
            navSlidingSpeed  = 250;

        // Configure collapsible functionality
        $('.' + navClass).each(function () {
            $(this).find('.' + navItemClass).has('.' + navSubmenuClass).children('.' + navItemClass + ' > ' + '.' + navLinkClass).not('.disabled').on('click', function (e) {
                e.preventDefault();

                // Simplify stuff
                var $target         = $(this),
                    $navSidebarMini = $('.sidebar-xs').not('.sidebar-mobile-main').find('.sidebar-main .' + navClass).children('.' + navItemClass);

                // Collapsible
                if ($target.parent('.' + navItemClass).hasClass(navItemOpenClass)) {
                    $target.parent('.' + navItemClass).not($navSidebarMini).removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
                } else {
                    $target.parent('.' + navItemClass).not($navSidebarMini).addClass(navItemOpenClass).children('.' + navSubmenuClass).slideDown(navSlidingSpeed);
                }

                // Accordion
                if ($target.parents('.' + navClass).data('nav-type') == 'accordion') {
                    $target.parent('.' + navItemClass).not($navSidebarMini).siblings(':has(.' + navSubmenuClass + ')').removeClass(navItemOpenClass).children('.' + navSubmenuClass).slideUp(navSlidingSpeed);
                }
            });
        });

        // Disable click in disabled navigation items
        $(document).on('click', '.' + navClass + ' .disabled', function (e) {
            e.preventDefault();
        });

        // Scrollspy navigation
        $('.nav-scrollspy').find('.' + navItemClass).has('.' + navSubmenuClass).children('.' + navItemClass + ' > ' + '.' + navLinkClass).off('click');
    }

    // Navbar navigation
    navigationNavbar() {

        // Prevent dropdown from closing on click
        $(document).on('click', '.dropdown-content', function (e) {
            e.stopPropagation();
        });

        // Disabled links
        $('.navbar-nav .disabled a, .nav-item-levels .disabled').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });

        // Show tabs inside dropdowns
        $('.dropdown-content a[data-toggle="tab"]').on('click', function (e) {
            // $(this).tab('show');
        });
    }

    sidebarMainResize() {

        // Flip 2nd level if menu overflows
        // bottom edge of browser window
        var revertBottomMenus = function () {
            $('.sidebar-main').find('.nav-sidebar').children('.nav-item-submenu').hover(function () {
                var totalHeight             = 0,
                    $this                   = $(this),
                    navSubmenuClass         = 'nav-group-sub',
                    navSubmenuReversedClass = 'nav-item-submenu-reversed';

                totalHeight += $this.find('.' + navSubmenuClass).filter(':visible').outerHeight();
                if ($this.children('.' + navSubmenuClass).length) {
                    if (($this.children('.' + navSubmenuClass).offset().top + $this.find('.' + navSubmenuClass).filter(':visible').outerHeight()) > document.body.clientHeight) {
                        $this.addClass(navSubmenuReversedClass);
                    } else {
                        $this.removeClass(navSubmenuReversedClass);
                    }
                }
            });
        };

        // If sidebar is resized by default
        if ($('body').hasClass('sidebar-xs')) {
            revertBottomMenus();
        }

        // Toggle min sidebar class
        $('.sidebar-main-toggle').on('click', function (e) {
            e.preventDefault();

            $('body').toggleClass('sidebar-xs').removeClass('sidebar-mobile-main');
            revertBottomMenus();
        });
    }

    // Toggle main sidebar
    sidebarMainToggle() {
        $(document).on('click', '.sidebar-main-hide', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-main-hidden');
        });
    }

    // Toggle secondary sidebar
    sidebarSecondaryToggle() {
        $(document).on('click', '.sidebar-secondary-toggle', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-secondary-hidden');
        });
    }

    // Show right, resize main
    sidebarRightMainToggle() {
        $(document).on('click', '.sidebar-right-main-toggle', function (e) {
            e.preventDefault();

            // Right sidebar visibility
            $('body').toggleClass('sidebar-right-visible');

            // If visible
            if ($('body').hasClass('sidebar-right-visible')) {

                // Make main sidebar mini
                $('body').addClass('sidebar-xs');

                // Hide children lists if they are opened, since sliding animation adds inline CSS
                $('.sidebar-main .nav-sidebar').children('.nav-item').children('.nav-group-sub').css('display', '');
            } else {
                $('body').removeClass('sidebar-xs');
            }
        });
    }

    // Show right, hide main
    sidebarRightMainHide() {
        $(document).on('click', '.sidebar-right-main-hide', function (e) {
            e.preventDefault();

            // Opposite sidebar visibility
            $('body').toggleClass('sidebar-right-visible');

            // If visible
            if ($('body').hasClass('sidebar-right-visible')) {
                $('body').addClass('sidebar-main-hidden');
            } else {
                $('body').removeClass('sidebar-main-hidden');
            }
        });
    }

    // Toggle right sidebar
    sidebarRightToggle() {
        $(document).on('click', '.sidebar-right-toggle', function (e) {
            e.preventDefault();

            $('body').toggleClass('sidebar-right-visible');
        });
    }

    // Show right, hide secondary
    sidebarRightSecondaryToggle() {
        $(document).on('click', '.sidebar-right-secondary-toggle', function (e) {
            e.preventDefault();

            // Opposite sidebar visibility
            $('body').toggleClass('sidebar-right-visible');

            // If visible
            if ($('body').hasClass('sidebar-right-visible')) {
                $('body').addClass('sidebar-secondary-hidden');
            } else {
                $('body').removeClass('sidebar-secondary-hidden');
            }
        });
    }

    // Toggle content sidebar
    sidebarComponentToggle() {
        $(document).on('click', '.sidebar-component-toggle', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-component-hidden');
        });
    }

    // Expand sidebar to full screen on mobile
    sidebarMobileFullscreen() {
        $('.sidebar-mobile-expand').on('click', function (e) {
            e.preventDefault();
            var $sidebar               = $(this).parents('.sidebar'),
                sidebarFullscreenClass = 'sidebar-fullscreen';

            if (!$sidebar.hasClass(sidebarFullscreenClass)) {
                $sidebar.addClass(sidebarFullscreenClass);
            } else {
                $sidebar.removeClass(sidebarFullscreenClass);
            }
        });
    }

    // Toggle main sidebar on mobile
    sidebarMobileMainToggle() {
        $('.sidebar-mobile-main-toggle').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-mobile-main').removeClass('sidebar-mobile-secondary sidebar-mobile-right');

            if ($('.sidebar-main').hasClass('sidebar-fullscreen')) {
                $('.sidebar-main').removeClass('sidebar-fullscreen');
            }
        });
    }

    // Toggle secondary sidebar on mobile
    sidebarMobileSecondaryToggle() {
        $('.sidebar-mobile-secondary-toggle').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-mobile-secondary').removeClass('sidebar-mobile-main sidebar-mobile-right');

            // Fullscreen mode
            if ($('.sidebar-secondary').hasClass('sidebar-fullscreen')) {
                $('.sidebar-secondary').removeClass('sidebar-fullscreen');
            }
        });
    }

    // Toggle right sidebar on mobile
    sidebarMobileRightToggle() {
        $('.sidebar-mobile-right-toggle').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-mobile-right').removeClass('sidebar-mobile-main sidebar-mobile-secondary');

            // Hide sidebar if in fullscreen mode on mobile
            if ($('.sidebar-right').hasClass('sidebar-fullscreen')) {
                $('.sidebar-right').removeClass('sidebar-fullscreen');
            }
        });
    };

    // Toggle component sidebar on mobile
    sidebarMobileComponentToggle() {
        $('.sidebar-mobile-component-toggle').on('click', function (e) {
            e.preventDefault();
            $('body').toggleClass('sidebar-mobile-component');
        });
    }

    /**
     * Dropdown submenus. Trigger on click
     */
    dropdownSubmenu() {

        // All parent levels require .dropdown-toggle class
        $('.dropdown-menu').find('.dropdown-submenu').not('.disabled').find('.dropdown-toggle').on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();

            // Remove "show" class in all siblings
            $(this).parent().siblings().removeClass('show').find('.show').removeClass('show');

            // Toggle submenu
            $(this).parent().toggleClass('show').children('.dropdown-menu').toggleClass('show');

            // Hide all levels when parent dropdown is closed
            $(this).parents('.show').on('hidden.bs.dropdown', function (e) {
                $('.dropdown-submenu .show, .dropdown-submenu.show').removeClass('show');
            });
        });
    }

    /**
     * Header elements toggler
     */
    headerElements() {

        // Toggle visible state of header elements
        $('.header-elements-toggle').on('click', function (e) {
            e.preventDefault();
            $(this).parents('[class*=header-elements-]').find('.header-elements').toggleClass('d-none');
        });

        // Toggle visible state of footer elements
        $('.footer-elements-toggle').on('click', function (e) {
            e.preventDefault();
            $(this).parents('.card-footer').find('.footer-elements').toggleClass('d-none');
        });
    };
}
